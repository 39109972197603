#projects {

  .overlay{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgb(1,0,18);
  background: -moz-linear-gradient(0deg, rgba(1,0,18,1) 0%, rgba(255,255,255,0) 50%);
  background: -webkit-linear-gradient(0deg, rgba(1,0,18,1) 0%, rgba(255,255,255,0) 50%);
  background: linear-gradient(0deg, rgba(1,0,18,1) 0%, rgba(255,255,255,0) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#010012",endColorstr="#ffffff",GradientType=1);
  opacity: 0.8;
  z-index: 1;
  }

  .content_con{
    z-index: 2;
  }

  @media all and (min-width: 768px) {
    .grider{
      display: none !important;
    }
    }

  .section_con {
    position: relative;
    z-index: 1;
    transition: opacity 0.1s ease-in-out;

    .mySwiper {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 95vh !important;

      @media all and (max-width: 769px) {
        display: none
      }

      .swiper-pagination{
        bottom: 0%;
      }

      .swiper-pagination-bullet{
        background: #ffffff;
        opacity: 1;
        height: 10px;
        width: 10px;
      }
      .swiper-pagination-bullet-active{
        background: #ff6ac3;
      }

      .swiper-wrapper {
        display: flex;
        justify-content: center;

        .swiper-slide {
          position: relative;
          transform: scale(1);
          visibility: visible;
          opacity: 1;
          height: auto;
          cursor: pointer;

          @media all and (max-width: 767px) {
            transform: scale(1);
            opacity: 1;
          }

          .content_con {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 10px;


            .project_title {
              display: flex;
              flex-direction: column;
              width: 50%;
              min-width: 50%;
              font-size: 2.5rem;
    
              @media all and (max-width: 1600px) {
                font-size: 1.5rem;
              }
            }

            .title {
              @media all and (min-width: 1024px) and (max-width: 1400px) {
                font-size: 1.2rem;
              }

              @media all and (max-width: 767px) {
                font-size: 1.2rem;
                align-self: flex-start;
              }
            }

            .category{
              font-size: 1rem;
              letter-spacing: .08rem;

              @media all and (min-width: 1024px) and (max-width: 1400px) {
                font-size: 0.8rem;
              }

              @media all and (max-width: 767px) {
                font-size: 0.8rem;
              }
            }
    
            .content {
              display: none;
            }
          }
        }
      }
    }
  } 

  .box {
    position: relative;
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    height: auto;
    grid-column: span 1 / span 1 !important;
    cursor: pointer;

    @media all and (max-width: 767px) {
      transform: scale(1);
      opacity: 1;
    }

    .content_con {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px;


      .project_title {
        display: flex;
        flex-direction: column;
        width: 50%;
        min-width: 50%;
        font-size: 2.5rem;

        @media all and (max-width: 1600px) {
          font-size: 1.5rem;
        }
      }

      .title {
        @media all and (min-width: 1024px) and (max-width: 1400px) {
          font-size: 1.2rem;
        }

        @media all and (max-width: 767px) {
          font-size: 1.2rem;
          align-self: flex-start;
        }
      }

      .category{
        font-size: 1rem;
        letter-spacing: .08rem;

        @media all and (min-width: 1024px) and (max-width: 1400px) {
          font-size: 0.8rem;
        }

        @media all and (max-width: 767px) {
          font-size: 0.8rem;
        }
      }

      .content {
        display: none;
      }
    }
  }
}