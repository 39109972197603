body.subpage {
  header {
    opacity: 1;
  }

  header .hamburger {
    opacity: 1;
  }

  header .logo {
    opacity: 1;
  }

  .fixed_footer_container {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
  .subpage{
    .header_con{
      background-color: black;
      padding: 0;
    }
  }

  #main{
    /*overflow: auto !important;*/
    overflow: hidden;
    padding-top: 40px;
  }
  /*
  body.subpage .container{
    max-width: 1240px;
  }

  */

  ul{
    margin-bottom: 30px;
  }
  
    ul > li{
        line-height: 1.5;
        margin-bottom: .5rem;
        text-indent: -1em;
        padding-left: 1em;
    }
    
    ul > li::before{
        color: #ff6ac3;
        content: "»";
        margin-right: 10px;
    }

    a{
      text-decoration: underline;
      cursor: pointer;
    }

    h3{
      font-size: 1.6rem;
      margin-bottom: 16px;
    }
    
    @media all and (max-width: 767px) {
        ul > li{
            font-size: 1.2rem;
            text-indent: -1em;
            padding-left: 1em;
        }
        p{
            font-size: 1.2rem;
        }
        #main {
          padding-top: 120px;
        }
    }
    
}