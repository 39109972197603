#solutions {
  
  .section_con {
  
    .section_content {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .entry_content {

        position: absolute;
        text-align: left;
        transform-origin: 0 50%;
        opacity: 0;
      }
    }
  }      
}