body {
  #approach {
    position: relative;
    overflow: hidden;

    // @media all and (min-width: 768px) {
    //   height: 100vh;
    // }

    svg{
      width: 100%;
    }

    .approach_container {
      @media all and (min-width: 768px) {
        position: absolute;
        width: 100%;
        max-width: 1300px;
        height: 800px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      @media all and (max-width: 767px) {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
      }

      .approach_step_wrapper {
        @media all and (min-width: 768px) {
          position: absolute;
          width: 600px;
          height: 600px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }

        @media all and (max-width: 767px) {
          position: relative;
          display: flex;
          flex-direction: column;
          height: auto;
          width: 100%;
          gap: 40px;
        }

        .step {
          @media all and (min-width: 768px) {
            position: absolute;
            left: 50%;
            top: 50%;
            opacity: 0;
            /* transform: translate(-50%, -50%); */
            transform: translate(calc(-50% - 250px), calc(-50% - 250px));
          }

          @media all and (max-width: 767px) {
            position: relative;
            opacity: 1;
            transform: translate(0, 0);
          }

          .adjuster {
            @media all and (min-width: 768px) {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }

            @media all and (min-width: 1401px) {
              width: 350px;
            }

            @media all and (min-width: 768px) and (max-width: 1400px) {
              width: 200px;
            }

            @media all and (max-width: 767px) {
              text-align: center;
            }
          }
        }

        #step_04,
        #step_05,
        #step_06 {
          .adjuster {
            @media all and (min-width: 768px) {
              text-align: right;
            }
          }
        }

        #step_01 {
          @media all and (min-width: 1401px) {
            transform: translate(calc(-50% + 250px), calc(-50% - 250px));
          }

          @media all and (min-width: 768px) and (max-width: 1400px) {
            transform: translate(calc(-50% + 150px), calc(-50% - 150px));
          }
        }

        #step_02 {
          @media all and (min-width: 1401px) {
            transform: translate(calc(-50% + 375px), calc(-50% - 0px));
          }

          @media all and (min-width: 768px) and (max-width: 1400px) {
            transform: translate(calc(-50% + 220px), calc(-50% - 0px));
            
          }
        }

        #step_03 {
          @media all and (min-width: 1401px) {
            transform: translate(calc(-50% + 250px), calc(-50% + 250px));
          }

          @media all and (min-width: 768px) and (max-width: 1400px) {
            transform: translate(calc(-50% + 150px), calc(-50% + 150px));
            
          }
        }

        #step_04 {
          @media all and (min-width: 1401px) {
            transform: translate(calc(-50% - 250px), calc(-50% + 250px));
          }

          @media all and (min-width: 768px) and (max-width: 1400px) {
            transform: translate(calc(-50% - 150px), calc(-50% + 150px));
            
          }
        }

        #step_05 {
          @media all and (min-width: 1401px) {
            transform: translate(calc(-50% - 375px), calc(-50% - 0px));
          }

          @media all and (min-width: 768px) and (max-width: 1400px) {
            transform: translate(calc(-50% - 220px), calc(-50% - 0px));
            
          }
        }

        #step_06 {
          @media all and (min-width: 1401px) {
            transform: translate(calc(-50% - 250px), calc(-50% - 250px));
          }

          @media all and (min-width: 768px) and (max-width: 1400px) {
            transform: translate(calc(-50% - 150px), calc(-50% - 150px));
            
          }
        }

        .top {
          display: flex;
          gap: 15px;
          flex-direction: row;

          @media all and (max-width: 767px) {
            justify-content: left;
          }
        }

        #step_04,
        #step_05,
        #step_06 {
          .top {
            @media all and (min-width: 768px) {
              flex-direction: row-reverse;
            }
          }
        }

        .step_number {
          font-size: 2.6rem;
          display: flex;
          transform: scale(1.2);
          visibility: visible;

          @media all and (min-width: 768px) and (max-width: 1400px) {
            font-size: 1.8rem;
          }

          @media all and (max-width: 767px) {
            font-size: 2rem;
          }
        }

        .step_headline {
          font-size: 2rem;
          display: flex;
          align-self: end;
          visibility: visible;
          
          @media all and (min-width: 768px) and (max-width: 1400px) {
            font-size: 1.4rem;
          }

          @media all and (max-width: 767px) {
            font-size: 1.5rem;
          }
        }

        .step_content {
          font-size: 1.5rem;
          visibility: visible;
          
          @media all and (min-width: 768px) and (max-width: 1400px) {
            font-size: 1rem;
          }

          @media all and (max-width: 767px) {
            font-size: 1.2rem;
            text-align: left;
          }
        }
      }
    }
  }

  .approach_outer_wrapper {
    @media all and (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media all and (min-width: 1401px) {
      width: 300px;
    }

    @media all and (min-width: 768px) and (max-width: 1400px) {
      width: 200px;
    }

    @media all and (max-width: 767px) {
      position: relative;
      width: 280px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .approach_inner_wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      // opacity: 0;
      transform: translate(-50%, -50%);

      @media all and (min-width: 1401px) {
        width: 220px;
      }
  
      @media all and (min-width: 768px) and (max-width: 1400px) {
        width: 150px;
      }

      @media all and (max-width: 767px) {
        width: 220px;
      }

      &.done {
        opacity: 1 !important;
      }
    }
  }
}
