.joboffers {
        li{
            list-style-type: none;
        }
        h2{
            font-size: 1.6rem;
            margin-bottom: 15px;
        }

        h2.acc-headline{
            font-size: 1.6rem;
            margin-bottom: 0;
        }

        a.job-btn{
            border: 1px solid #ff6ac3;
            border-radius: 50px;
            padding: 9px 20px 7px 20px;
            font-size: 1.2rem;
            transition: 0.3s;
            display: inline-block;
            margin-bottom: 20px;
        }
        a.job-btn:hover{
            border: 1px solid #ff6ac3;
            background-color: #ff6ac3;
            margin-bottom: 20px;
            color: #ffffff;
        }
        .job-content p{
            margin-bottom: 1.5rem;
        }

        

        h1{
            margin: 0;
            padding: 0;
        }

        @media all and (max-width: 767px) {
            h2.acc-headline{
                font-size: 1.2rem;
                margin-bottom: 0;
            }
            a.job-btn{
                font-size: 1rem;
            }
        }
        
}