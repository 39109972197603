body {

  .headline_container {
    position: fixed;
    left: 10vw;
    top: 100px;
    width: 120px;
    height: calc(100% - 100px);
    overflow: hidden;
    z-index: 2;
    visibility: hidden;
  }

  .headline_container .main_headline {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  .headline_container .main_headline span {
    font-family: Nunito, sans-serif;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 80px;
    text-transform: uppercase;
    line-height: 1;
    transform: rotate(-90deg) translate(50%, 50%);
    position: absolute;
    transform-origin: 100% 0px;
    top: 100%;
    right: 0px;
    width: max-content;
    opacity: 0;
  }

  .section_outer {
    position: relative;
    width: 95%;
    max-width: 1900px;
    margin: auto;
    z-index: 1;

    @media all and (max-width: 767px) {
      width: 90%;
      margin-bottom: 60px;
    }

    &.active {
      z-index: 2;
    }
  }

  .section_headline_container_outer {
    @media all and (min-width: 768px) {
      position: relative;
      display: block;
      width: 100%;
      // width: 1700px;
      margin: auto;
    }

    @media all and (max-width: 767px) {
      font-family: Nunito, sans-serif;
      font-weight: 500;
      color: #FFFFFF;
      font-size: 40px;
      text-transform: uppercase;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 40px;
    }

    @media all and (max-width: 424px) {
      font-size: 30px;
      text-align: left;
    }
  }

  .section_headline_container {
    @media all and (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      // width: 100%;
      height: 100vh;
      z-index: 2;
    }
  }
  
  .section_headline_container .section_headline {
    @media all and (min-width: 768px) {
      // font-family: Nunito, sans-serif;
      // font-weight: 500;
      // color: #FFFFFF;
      // font-size: 80px;
      // text-transform: uppercase;
      // line-height: 1;
      // transform: rotate(-90deg) translate(50%, 50%);
      // position: absolute;
      // transform-origin: 100% 50%;
      // // transform-origin: 100% 0px;
      // top: 50%;
      // right: 0px;
      // width: max-content;
      // opacity: 1;
    }

    @media all and (min-width: 768px) {
      font-family: Nunito, sans-serif;
      font-weight: 500;
      color: #FFFFFF;
      font-size: 50px;
      text-transform: uppercase;
      line-height: 1;
      // transform: rotate(-90deg) translate(-50%, 5px);
      transform: rotate(-90deg) translate(-50%, 0px);
      position: absolute;
      transform-origin: -0% -0%;
      top: 50%;
      left: 3px;
      width: max-content;
      opacity: 1;
    }
  }

  section {
    position: relative;
    height: 100vh;

    @media all and (max-width: 767px) {
      height: auto;
      margin-bottom: 60px;
    }
  }

  .section_con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 80%;
    // max-width: 1300px;
    margin: auto;
    gap: 16px;

    @media all and (max-width: 767px) {
      height: auto;
      width: 100%;
    }
  }

  .sub_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 90%;
    max-width: 1300px;
    margin: auto;
  }

  .video_wrapper {
    @media all and (min-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;
    }

    @media all and (max-width: 767px) {
      // display: none;
      margin-bottom: 40px;
    }

    .video_overlay {
      @media all and (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #212126;
        opacity: 0;
      }
    }
  
    .video_bg {
      @media all and (min-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // z-index: -1;
      }
    }
  }

  .intro_video_wrapper {
    @media all and (min-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    @media all and (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    // @media all and (max-width: 767px) {
    //   // display: none;
    //   margin-bottom: 40px;
    // }

    .video_overlay {
      @media all and (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(29, 29, 32, 1);
        opacity: 0;
        z-index: 1;
      }

      @media all and (max-width: 767px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(29, 29, 32, 1);
        opacity: 0;
        z-index: 1;
      }
    }
  
    .video_bg {
      @media all and (min-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // z-index: -1;
      }

      @media all and (max-width: 767px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // z-index: -1;
      }
    }
  }

  .main_layer {
    // display: flex;
    display: none;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media all and (max-width: 767px) {
      display: flex;
    }
  }

  .entry_layer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media all and (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
}