.subpage {
  .section_headline_container .section_headline{
    left: 4px !important;
  }
  @media all and (max-width: 767px) {
    .section_headline_container_outer{
      display: none;
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper {
    width: 90%;
    height: 80%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #212126;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .swiper-button-next, .swiper-button-prev{
    color: #ff6ac3 !important;
  }

  .swiper-button-next:after, .swiper-button-prev:after{
    font-size: 2rem;
  }
  .swiper-button-next{
    right: 20px;
  }
  .swiper-button-prev{
    left: 20px;
  }
}