#services {

  .section_video_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    // background-color: green;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      // display: none;
    }
  }
  
  .adjuster {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 80px;
    width: 85%;

    @media all and (max-width: 1200px) {
      flex-direction: column;
      gap: 40px;
    }

    @media all and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0px;
    }

    .entry_headline,
    .entry_content {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      align-self: flex-start;
      text-align: left;
    }

    .main_layer {
      display: flex;
    }

    li {
      opacity: 0;
      transform: scale(1.2);
      transform-origin: 0 50%;

      @media all and (max-width: 767px) {
        opacity: 1;
        transform: scale(1);      
      }
      &::before{
        content: '»';
        color: #ff6ac3;
        margin-right: 10px;
      }
    }
  }
}
    