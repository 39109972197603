#projects {

  .section_con {
    position: relative;
    z-index: 1;
    transition: opacity 0.1s ease-in-out;

    .project_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      gap: 80px;

      nav{
        a:hover{
          color: #ff6ac3;
        }
      }

      @media all and (max-width: 1023px) {
        flex-direction: column;
      }

      @media all and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      }

      .project_items_container {
        display: flex;
        flex-direction: column;
        width: 40%;
        min-width: 40%;
  
        @media all and (min-width: 1024px) and (max-width: 1700px) {
          width: 50%;
          min-width: 50%;
        }

        @media all and (max-width: 1023px) {
          width: 100%;
        }
  
        @media all and (max-width: 767px) {
          width: 100%;
        }

        .project_item:first-child {
          padding-top: 0;
        }

        .project_item {

          padding-right: 0rem;
  
          @media all and (max-width: 424px) {
            flex-direction: column-reverse;
          }

          .project_title {
            display: flex;
            flex-direction: column;
            width: 50%;
            min-width: 50%;
            font-size: 2.5rem;
  
            @media all and (max-width: 1600px) {
              font-size: 1.5rem;
            }
          }

          .title {
            @media all and (min-width: 1024px) and (max-width: 1400px) {
              font-size: 1.2rem;
            }

            @media all and (max-width: 767px) {
              font-size: 1.2rem;
              align-self: flex-start;
            }
          }

          .category{
            font-size: 1rem;
            letter-spacing: .08rem;

            @media all and (min-width: 1024px) and (max-width: 1400px) {
              font-size: 0.8rem;
            }

            @media all and (max-width: 767px) {
              font-size: 0.8rem;
            }
          }
  
          .content {
            display: none;
          }
        }
      }

      .project_preview_container {
        display: flex;
        // display: inline-block;
        flex-direction: column;
        position: relative;
        width: 60%;
        // float: right;
        height: 100%;
  
        @media all and (max-width: 1023px) {
          display: none;
        }

        @media all and (max-width: 767px) {
          display: none;
        }

        .project_preview {
          position: absolute;
          width: 100%;
          min-width: 100%;
          top: 0;
          bottom: 0;
          margin: auto;
          display: inline-table;
          visibility: hidden;
  
          &.active {
            visibility: visible;
          }
        }

        .award {
          position: absolute;
          padding: 20px;
          width: 30%;
        }
      }

      &.inview {
      
        .project_preview {
          
          .project_image {
            position: relative;
    
            &.active {
              display: flex;
              width: 100%!important;
              inline-size: 100%!important;
              block-size: unset!important;
            }
          }
        }
      }

      &:not(.inview) {
      
        .project_preview {

          .project_image {
            display: none;
          }
        }

        #project_01_preview {
          
          .project_image {
            display: flex!important;
            width: 100%!important;
            inline-size: 100%!important;
            block-size: unset!important;
          }
        }

        .project_slider {
          display: none;
        }
      }

      &.project_hover {

        .project_preview {
          visibility: hidden;
  
          &.active {
            visibility: hidden!important;
          }
  
          &.project_hover {
            visibility: visible!important;
          }
        }
      }
    }
  } 
  
  .popup_con {
    @media all and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100vh;
      width: 100%;
      margin: auto;
      gap: 16px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      visibility: hidden;
      max-width: 1900px;
      // overflow: auto;
      opacity: 0;
      background: rgba(29, 29, 32, 1);
    }

    @media all and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100vh;
      width: 100%;
      gap: 16px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      visibility: hidden;
      // overflow: auto;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    .popup {
      background: transparent;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      position: absolute;
      gap: 60px;
      opacity: 0;
      transform: translateY(-100%);
      padding: 20px 140px;
      // overflow: auto;
      max-height: 100vh;
      
      @media all and (max-width: 767px) {
        background: rgba(29, 29, 32, 1);
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        position: absolute;
        gap: 60px;
        opacity: 0;
        padding: 40px 20px;
        min-height: 100vh;
        padding: 20px;
      }

      &.active {
        // visibility: visible;
        opacity: 1;
      }

      .popup_header {
        padding-top: 20px;
      
        @media all and (max-width: 767px) {
          padding-top: 0px;
          position: relative; 
        }

        button.close {
          position: absolute;
          right: 55px;
          top: 35px;
          width: 40px;
          height: 40px;
          z-index: 1;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;

          @media all and (max-width: 767px) {
            right: -7px;
            top: 0;
          }

          svg {
            fill: #FFFFFF;
          }
        }
      }

      .popup_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
        overflow: auto;

        @media all and (min-width: 768px) {
          flex-direction: row;
        }

        @media all and (max-width: 767px) {
          flex-direction: column;
        }

        .popup_details {
          display: flex;
          flex-direction: column;
          gap: 25px;
          width: 50%;

    
          @media all and (max-width: 767px) {
            gap: 30px;
            min-width: 100%;
            width: 100%;
          }

          .popup_awards_containber {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .award {
              max-width: 120px;

              @media all and (max-width: 767px) {
                max-width: 80px;
              }
            }
          }

          .popup_project_link {
            display: flex;
            align-self: flex-start;

            &:after {
              content:"";
              height: 1px;
              background: #FFFFFF;
              width: 100%;
              margin: 5px auto 0 auto;
            }
          }
        }

        .popup_slider {
          // display: flex;
          // min-width: 45%;
          // width: 45%;
          display: flex;
          min-width: auto;
          width: 45%;
          justify-content: flex-end;

          @media all and (max-width: 767px) {
            min-width: 100%;
            width: 100%;
          }

          .slider{
            position:relative;
            width:100%;
            overflow:hidden;
            // margin:20px auto 0 auto;
          }
          
          .slider ul{
            position:relative;
            width:100%;
            margin: 0;
            padding:0;
            // height:200px;
            display:inline-block;
            list-style:none;
            // background:#458;
          }
          
          
          .slider ul li{
            position:relative;
            float: left;
            margin:0;
            padding:0;
            // width:900px;
            // height:300px;
            background:#fff;
            text-align:center;
            // line-height:300px;
            // color:#333;

            img {
              display: block;
              max-width: 100%;
              width: auto;
              max-height: 80vh;
            }
          }
          
          .control{
            position: absolute;
            top: 50%;
            z-index: 9;
            display: block;
            padding: 0;
            cursor: pointer;
            transform: translateY(-50%);
          }
          
          .prev{
            left:10px;
            width: 30px;

            // svg {
            //   fill: #FFFFFF;
            //   width: 60px;
            // }
          }
          
          .next{
            right:10px;
            width: 30px;

            // svg {
            //   fill: #FFFFFF;
            //   width: 60px;
            // }
          }
        }
      }

      &.horizontal {

        .popup_content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 50px;
      
          .popup_details {
            width: 100%;
          }

          .popup_images {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 15px;

            @media all and (max-width: 767px) {
              flex-direction: column;
            }
            
            .image {
              display: flex;
            }
          }
        }
      }
    }
  }
}

body {

  &.project_view_active {
    overflow: hidden;

    #projects {
      position: relative;
      z-index: 999;

      section.projects {
        z-index: 9;

        .section_con {
          @media all and (min-width: 768px) {
            opacity: 0;
            transition: opacity 0.2s 0.5s ease-in-out;
          }
        }
  
        .popup_con {
          opacity: 0.95;
          z-index: 2;
          visibility: visible;
          
          @media all and (max-width: 767px) {
            opacity: 1;
          }

          .popup.active {
            transform: translateY(0%);
            transition: transform 0.3s 0.2s ease-in-out;

            button.close {
              opacity: 1;
              transition: opacity 0.2s 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }
}