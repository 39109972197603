body {

  #intro {
    z-index: 1;
    
    &.active {
      z-index: 3;
    }

    .video_wrapper {
      @media all and (max-width: 767px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        margin-bottom: 0;
      }
    }
  
    .video_overlay {
      @media all and (max-width: 767px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(29, 29, 32, 1);
        opacity: 0;
      }
    }
  
    .video_bg {
      @media all and (max-width: 767px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // z-index: -1;
      }
    }

    // section {
    // }

    .section_con {
      gap: 30px;

      @media all and (max-width: 767px) {
        height: 100vh;
      }
    }

    .intro_img img {
      padding: 120px 0 0 0;
      width: 200px;
      transform: scale(1.5);
      visibility: visible;
      opacity: 0;
    }

    .intro_content .inner {
      text-transform: uppercase;
      max-width: 880px;
      text-align: left;
      font-weight: 800;
      opacity: 0;
      transform: scale(1.2);
      color: rgba(255, 107, 195, 1);
      margin: 0 auto;
    }

    .intro_scroller {
      
      .scroll-icon {
        position: absolute;
        bottom: 5%;
        z-index: 99;
        width: 90%;
        max-width: 600px;
        height: 20px;
        border-radius: 25px;
        box-shadow: inset 0 0 0 1px #ddd;
        overflow: hidden;
        transform: translateX(-50%);
        left: 50%;
        margin: auto;
        background-color: transparent;
      }
    }
  }
  

  &.loading {
    
    #intro {

      .intro_img img {
        // animation: anim_intro_img 1s forwards ease-in-out;
        // animation-iteration-count: 1;
        transform: scale(1);
        opacity: 1;
        transition: all 0.3s 0.30s cubic-bezier(.7,.16,.2,.9);
      }

      .intro_scroller {
        pointer-events: none;

        .scroll-icon {
          transition: top 0.3s linear, opacity 0.3s linear;
          opacity: 1;
          transform: translateX(-50%);

          &:before {
            content: "";
            position: absolute;
            left: 3px;
            background-color: rgba(255, 107, 195, 1);
            width: 0;
            height: calc(100% - 8px);
            margin-left: 0;
            top: 4px;
            border-radius: 25px;
            -webkit-animation: loaderAnim 2s forwards ease-in-out;
            animation: loaderAnim 2s forwards ease-in-out;
          }
        }
      }
    }
  }

  &.loaded {
    
    #intro {

      .intro_img img {
        padding: 0;
        transform: scale(1);
        opacity: 1;
        // transition: padding 0.3s ease-in-out;
        transition: all 0.3s 0.30s cubic-bezier(.7,.16,.2,.9);
      }

      .intro_content .inner {
        opacity: 1;
        // transition: opacity 1s ease-in-out;
        transform: scale(1);
        transition: all 0.3s 0.30s cubic-bezier(.7,.16,.2,.9);
      }

      .intro_scroller {
        pointer-events: inherit;

        .scroll-icon {
          position: absolute;
          left: 50%;
          margin: auto;
          bottom: 5%;
          z-index: 99;
          width: 38px;
          height: 58px;
          border-radius: 25px;
          box-shadow: inset 0 0 0 1px #ddd;
          background-color: transparent;
          transform: translateX(-50%);
          transition: all 0.3s linear;

          &:before {
            content: "";
            position: absolute;
            left: 50%;
            background-color: #ddd;
            width: 8px;
            height: 8px;
            margin-left: -4px;
            top: 8px;
            border-radius: 4px;
            -webkit-animation: scroll 1.5s infinite;
            animation: scroll 1.5s infinite;
          }
        }
      }
    }
  }
}