
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'base';
@import 'header';
@import 'sections';
@import 'progress-scroll';
@import 'section_intro';
@import 'section_solutions';
@import 'section_projects';
@import 'section_projects_grid';
@import 'section_usp';
@import 'section_services';
@import 'section_approach';
@import 'section_thatsus';
@import 'section_joboffers';
@import 'section_part';
@import 'section_partners';
@import 'section_contact';
@import 'subpage';
@import 'keyframes';
@import 'project_detail';

@import "~aos/dist/aos.css";


p{
  margin-bottom: 15px;
}

.sub{
  padding-bottom: 150px;
}

@media all and (max-width: 767px) {
  .sub{
    padding-bottom: 50px;
  }
}

strong{
  font-weight: 700;
}

.gap-10{
  gap: 5rem;
}

div#scroll_debugger {
  position: fixed;
  left: 0;
  top: 50vh;
  transform: translateY(-50%);
  padding: 10px 20px;
  background: aquamarine;
  color: #000000;
  z-index: 999;
  display: none;
}

.nav-item {
  @apply text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out uppercase text-lg;
}

.section_heading span {
  @apply uppercase;
}

body {
  /* background-color: theme('colors.dark'); */
  @apply m-0 bg-dark font-nunito text-2xl font-light tracking-tight text-white;
}

header {
/* @apply bg-dark; */
}

.headline {
  @apply flex flex-col self-start font-nunito uppercase font-semibold text-white text-2xl md:text-3xl;
}

.entry_headline {
  @apply flex flex-col self-start font-nunito uppercase font-normal text-white self-center text-2xl md:text-3xl xl:text-3xl text-center tracking-tighter;
}

.entry_headline_jobs {
  @apply flex flex-col self-start font-nunito uppercase font-normal text-white self-start text-2xl md:text-3xl xl:text-3xl text-start tracking-tighter pb-3;
}

.content {
  @apply flex flex-col self-start w-full text-md md:text-lg text-white dark:text-white;
}

.entry_content {
  @apply flex flex-col self-start w-full text-lg text-white dark:text-white justify-center text-lg md:text-xl text-pink-900 dark:text-pink-900 text-center;
}

.subline {
  @apply flex flex-col self-start font-nunito text-white uppercase tracking-widest text-base pl-5 relative;
}

.section_link {
  @apply flex flex-col self-start tracking-widest text-pink-900 dark:text-pink-900 uppercase text-left text-base;
}

.content a {
 @apply text-pink-900;
}

/* section projects */
/* 
.mySwiper{
  @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4;
}
*/

.project_headline {
  @apply flex flex-col self-start font-nunito uppercase font-medium text-white text-2xl md:text-3xl;
}

.project_content {
  @apply w-full text-lg text-white dark:text-white justify-center text-lg md:text-xl text-pink-900 dark:text-pink-900 text-left;
}

#projects .box {
	@apply xl:hidden;
}
#projects .grider {
  @apply grid overflow-hidden grid-cols-1 grid-rows-1 md:grid-cols-2 gap-2 w-full;
}

#projects .project_item {
  @apply flex flex-row justify-between items-end w-full pb-2 pt-5 border-b-2 border-white cursor-pointer pr-5;
}

#projects .project_item .title {
  @apply flex font-nunito font-normal text-white;
}

#projects .project_container:not(.project_hover) .project_item.active .title {
  @apply text-pink-900;
}

#projects .project_container.project_hover .project_item.project_hover .title {
  @apply text-pink-900;
}

#projects .project_item .category {
  @apply flex font-nunito text-white tracking-widest text-base;
}

section#projects button {
  @apply py-3 inline-flex items-center gap-x-3 w-full uppercase text-left text-white transition hover:text-pink-900 dark:text-white dark:hover:text-pink-900;
}

/* project popup */
.popup_title {
  @apply flex flex-col self-start font-nunito uppercase font-semibold text-white text-2xl md:text-3xl;
}

/* .entry_headline {
  @apply flex flex-col self-start font-nunito uppercase font-semibold text-white self-center text-3xl md:text-4xl text-center tracking-tighter;
} */

.popup_description {
  @apply flex flex-col self-start w-full text-md text-white;
}

.popup_entry_description {
  @apply flex flex-col self-start w-full text-md text-pink-900 dark:text-pink-900 font-bold;
}

.subline {
  @apply flex flex-col self-start font-nunito text-white uppercase tracking-widest text-base pl-5 relative;
}

.popup_project_link {
  @apply flex flex-col self-start tracking-widest text-pink-900 dark:text-pink-900 uppercase text-left text-base;
}

/* section services */
#services li {
  @apply flex text-md md:text-lg mb-2 md:mb-3;
}

#services li span.icon {
  @apply text-pink-900 pr-3 md:pr-4;
}

.video_wrapper{
  @apply hidden lg:block;
}

/* section approach */
.step_number {
  @apply flex flex-col self-start font-nunito uppercase font-semibold text-pink-900 text-4xl;
}

.step_headline {
  @apply flex flex-col self-start font-nunito uppercase font-semibold text-white text-3xl;
}

.approach_outer_wrapper{
  @apply hidden lg:block;
}

.step_content {
  
}

/* section thatsus */
.team_members {
  @apply grid grid-cols-3 md:grid-cols-4 xl:grid-cols-8 gap-4 w-full;
}

.member {
  @apply col-span-1; 
}

.member-hover{
  @apply opacity-0 group-hover:opacity-80 duration-300 absolute left-0 bottom-0 right-0 z-10 text-base 
  bg-black text-white font-normal py-1 px-2;
}

.member.wide {
  @apply col-span-2;
}

.member .name {
  @apply font-nunito font-normal text-white text-lg p-0 md:p-2;
}

.member .description {
  @apply font-nunito font-normal text-white text-base p-0 md:p-2;
}

/* section partners */
.logo_container_partners {
  @apply grid gap-4 grid-cols-2 md:grid-cols-6 w-11/12;
}

/* section part */
.logo_container {
  @apply grid gap-4 grid-cols-2 md:grid-cols-5 w-10/12;
}

.logo_item {
  @apply flex items-center justify-center;
}

.logo_item img {
  @apply w-full max-w-[175px];
}

.logo_item_partners img {
  @apply w-full;
}

/* section contact */
#contact .content {
  @apply text-left md:text-left;
}

#contact .content p {
  @apply text-md md:text-lg text-white mb-4 uppercase;
}