body {
  
  header, .header {
    opacity: 0;
    position: fixed;
    width: 95%;
    max-width: 1900px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    z-index: 10;
    margin: auto;
    left: 0;
    right: 0;

    @media all and (max-width: 1023px) {
      width: 100%;
      padding: 16px 2.5%;
    }

    @media all and (max-width: 767px) {
      // background: rgba(29, 29, 32, 1);
      // position: relative;
      // display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-around;
      background: #1d1d20;
    }
  
    .header_con {
      // position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .section_main_headline {
      position: absolute;
      top: 100%;
    }

    .hamburger {
      opacity: 0;
      display: flex;
      align-self: center;
      flex-direction: column;
      justify-content: space-between;
      min-width: 50px;
      height: 20px;
      cursor: pointer;
      left: 35px;
      top: 40px;
      z-index: 1;
    }

    nav {
      display: flex;
      transform: translateY(-100%);
      opacity: 0;
      transition: all 0.3s ease-in-out;

      @media all and (max-width: 1023px) {
        display: flex;
        transform: translateY(-100%);
        opacity: 0;
        transition: all 0.3s ease-in-out;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(29, 29, 32, 1);
      }
        
      ul {
        display: flex;
        gap: 40px;
        list-style-type: none;
        
        @media all and (max-width: 1900px) {
          gap: 30px;
        }

        @media all and (max-width: 1400px) {
          gap: 24px;
        }

        @media all and (max-width: 1200px) {
          gap: 16px;
        }

        @media all and (max-width: 1023px) {
          display: flex;
          flex-direction: column;
          gap: 20px;
          list-style-type: none;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        a.langswitch_de:hover, a.langswitch_en:hover{
          color: #ff6bc3;
        }

        a.langswitch_de.active, a.langswitch_en.active{
          color: #ff6bc3;
        }

        li {
          display: flex;
          // gap: 40px;
          list-style-type: none;


          a {
            font-size: 24px;
            text-transform: uppercase;
            color: rgba(212, 212, 216, 1);
            text-decoration: none;
            transition: all 0.2s ease-in-out;

            @media all and (max-width: 1900px) {
              font-size: 19px;
            }

            @media all and (max-width: 1400px) {
              font-size: 16px;
            }

            @media all and (max-width: 1200px) {
              font-size: 15px;
            }

            @media all and (max-width: 1023px) {
              font-size: 40px;
            }
          }

          &:hover,
          &:focus {

            a {
              color: #FFFFFF;
            }
          }

          &.active {

            a {
              color: rgba(255, 107, 195, 1);
            }
          }

          &.hide {
            display: none;
          }
        }
      }
    }

    .logo {
      opacity: 0;
      display: flex;
      min-width: 60px;
      height: auto;
      cursor: pointer;
      transition: opacity 1s ease-in-out;

      @media all and (max-width: 767px) {
        min-width: 50px;
        opacity: 1;
      }
    }

    &.active {
      
      nav {
        transform: translateY(0%);
        opacity: 1;
      }
    }
  }

  &.loaded {
    
    header {
      opacity: 1;
    }

    .hamburger {
      opacity: 1;
      transition: opacity 1s ease-in-out;
    }
  }

  &.scrolled {
    
    .logo {
      opacity: 1;
    }    
  }
}

header:not(.active){
  .hamburger {
    
    div {
      align-self: flex-start;
      height: 3px;
      width: 100%;
      background: #ffffff;
    }

    .meat {
      width: 75%;
      transition: all 200ms ease-in-out;
    }
    
    .bottom-bun {
      width: 50%;
      transition: all 400ms ease-in-out;
    }

    &:hover,
    &:focus {

      div {
        width: 100%;
      }

      .top-bun {
        -webkit-animation: burger-hover 1s infinite ease-in-out alternate;
        animation: burger-hover 1s infinite ease-in-out alternate;
      }
      
      .meat {
        -webkit-animation: burger-hover 1s infinite ease-in-out alternate forwards
            200ms;
        animation: burger-hover 1s infinite ease-in-out alternate forwards 200ms;
      }
      
      .bottom-bun {
        -webkit-animation: burger-hover 1s infinite ease-in-out alternate forwards
            400ms;
        animation: burger-hover 1s infinite ease-in-out alternate forwards 400ms;
      }
    }
  }
}

header.active{
  .hamburger {
    div {
      align-self: flex-start;
      height: 3px;
      width: 100%;
      background: #ffffff;
    }

    .top-bun {
      transform: rotate(45deg);
      transform-origin: 0 0;
      width: 40px;
      margin: 0 0 0 2px;
    }

    .meat {
      display: none;
    }
    
    .bottom-bun {
      transform: rotate(-45deg);
      transform-origin: 0 0;
      width: 40px;
      margin: 0 0 -11px 0;
    }
  }
}

