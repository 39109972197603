#partners {
  
  .section_con {
    
    .logo_item_partners {
      position: relative;
      transform: scale(0.8);
      visibility: visible;
      opacity: 0;
      // transition: all 0.3s 0.30s cubic-bezier(.7,.16,.2,.9);

      @media all and (max-width: 767px) {
        transform: scale(1);
        opacity: 1;  
      }

      // &.active {
      //   transform: scale(1);
      //   opacity: 1;    
      // }

      // &:hover,
      // &:focus {

      // }
    }
  }      
}
