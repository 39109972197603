.progress-wrap {
  position: fixed;
  bottom: 30px;
  right: 52px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 400ms linear;

  @media all and (max-width: 1400px) {
    right: 2.5%;
  }

  @media all and (max-width: 767px) {
    right: 20px;
    bottom: 10px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}



.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #ff6ac3;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 400ms linear;
}

.progress_icon {
  position: absolute;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    path {
      fill: #ff6ac3;
    }
  }
}