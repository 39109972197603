body {
  #contact {
    .section_con {
      @media all and (max-width: 767px) {
        padding-bottom: 100px;
        margin-bottom: -100px;
      }
      .headline {
        @media all and (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}