body {
  // height: 100vh;
  // overflow: hidden;

  #main {
    overflow: hidden;
  }

  .subline {
    
    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #fe6bc3;
      position: absolute;
      left: 6px;
      bottom: 6px;

      &:first-of-type {
        bottom: 12px;
      }

      &:last-of-type {
        left: 0px;
      }
    }
  }

  &.loaded {
    height: auto;
    overflow: auto;
  }
}

.subline,
.headline,
.content,
.section_link {
  transform-origin: 0 100%;
}

.contact_btn {
  position: absolute;
  z-index: 9;
  bottom: 0;
  left: 0;
  background: #1d1d20;
  border: 1px solid rgba(255, 107, 195, 1);
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 100%;
  line-height: 1;
  padding: 13px 13px;
  font-weight: 500;
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: all 400ms linear;
  pointer-events: all;

  @media all and (max-width: 767px) {
    left: 20px;
    bottom: 10px;
  }

  &.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.footer_container {
  max-width: 1900px;
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  height: 50px;
  margin: auto;
  bottom: 30px;
  width: 95%;
  z-index: 20 !important;
  ul.footer-menu {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-self: center;
    margin: auto;
    gap: 15px;
    font-size: 16px;
    text-align: center;

    @media all and (max-width: 374px) {
      flex-direction: column;
      gap: 3px;
    }
  }
}

@media all and (max-width: 500px) {
  .footer_container {
    bottom: 10px;
  }
}

.fixed_footer_container {
  max-width: 1900px;
  position: relative;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  height: 50px;
  margin: auto;
  bottom: 30px;
  width: 95%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 400ms linear;
  pointer-events: none;

  &.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  @media all and (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
    bottom: 0;
    /* background: rgba(29, 29, 32, 1); */
    height: 64px;
  }
}

@media all and (max-width: 767px) {
  #main {
    padding-bottom: 100px;
  }
}