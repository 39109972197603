#thatsus {
  
  .section_con {
  position: relative;
  top: initial;
  bottom: initial;
  opacity: 1;

  .join h2{
    color: #ffffff;
  }

    .member {
      position: relative;
      transform: scale(0.8);
      visibility: visible;
      opacity: 0;
      // transition: all 0.3s 0.30s cubic-bezier(.7,.16,.2,.9);
      
      &.join{
        font-size: 1.2rem;
      }

      @media all and (max-width: 767px) {
        transform: scale(1);
        opacity: 1;  
      }
      
      // &.active {
      //   transform: scale(1);
      //   opacity: 1;    
      // }

      // &.fade-in {
      //   transform: scale(1);
      //   opacity: 1;    
      // }

      img {
        opacity: 1;
        transition: all 0.6s;
      }

      img.rollover {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }


      .name {
        position: absolute;
        transform: scale(0.8);
        opacity: 0;
        transition: all 0.3s 0.45s cubic-bezier(.7,.16,.2,.9);
      }

      .description {
        position: absolute;
        bottom: 0;
        transform: scale(0.8);
        opacity: 0;
        transition: all 0.3s 0.45s cubic-bezier(.7,.16,.2,.9);
      }

      &:hover,
      &:focus {

        img {
          cursor: pointer;
          opacity: 0;
        }

        img.rollover {
          opacity: 1;
        }


        .name {
          opacity: 1;
        }

        p{
          margin: 0;
        }

        .description {
          opacity: 1;
        }
      }
    }
  }      
}