// Hamburger Menu Animation
@-webkit-keyframes burger-hover {
  0% {
    width: 100%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

@keyframes burger-hover {
  0% {
    width: 100%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
} 

// Intro Image Animation
@keyframes anim_intro_img {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  
  50% {
    transform: scale(0.7);
  }

  70% {
    opacity: 1;
    transform: scale(0.6);
  }

  100% {
    transform: scale(1);
  }
} 

// Intro Loader Animation
@-webkit-keyframes loaderAnim {
  0% {
    opacity: 0.4;
    width: 0;
  }

  100% {
    opacity: 1;
    width: calc(100% - 6px);
  }
}

@keyframes loaderAnim {
  0% {
    opacity: 0.4;
    width: 0;
  }

  100% {
    opacity: 1;
    width: calc(100% - 6px);
  }
}

// Intro Scroll Animation
@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(16px);
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(16px);
  }
}