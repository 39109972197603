#usp {
  
  .section_con {
    position: relative;
    width: 100%;
  }      
}

.arrows {
  width: 60px;
  height: 72px;
  position: relative;
  left: 30%;
  margin-left: -30px;
  bottom: 20px;
}

.arrows path {
	stroke: #ff6ac3;
	fill: transparent;
	stroke-width: 1px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-1s;
	-webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}

.section_cards_outer {
  @media all and (min-width: 768px) {    
    // overflow: hidden;
    position: relative;
    margin-right: auto;
    width: 100%;
    left: 0%;
  }

  // @media all and (min-width: 1401px) {
  //   width: 210%;
  //   left: -7%;
  // }

  // @media all and (min-width: 1024px) and (max-width: 1400px) {
  //   width: 270%;
  //   left: 10%;
  // }

  // @media all and (min-width: 768px) and (max-width: 1023px) {
  //   width: 300%;
  //   left: 0%;
  // }

  @media all and (max-width: 767px) {
    position: relative;
    display: flex;
    width: 100%;
  }

  .section_cards {
    @media all and (min-width: 768px) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      flex-shrink: 0;
      margin-right: auto;
    }
    
    @media all and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    .section_card {
      // @media all and (min-width: 768px) {
      //   // width: 66.66%;
      //   width: 33.33%;
      //   // width: calc(33.33% - 80px);
      //   flex-shrink: 0;
      //   display: flex;
      //   flex-direction: column;
      //   gap: 16px;
      //   padding-left: 80px;
      // }

      @media all and (min-width: 768px) {
        // width: 66.66%;
        width: 60%;
        // width: calc(33.33% - 80px);
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-left: 170px;
      }

      @media all and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .entry_headline {
        align-self: flex-start;
        text-align: left;
      }
    }
  }
}
